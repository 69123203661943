<template>
  <a-drawer
    v-model:visible="visible"
    destroyOnClose
    centered
    width="600px"
    :class="['info-drawer', 'info-drawer-temp-' + tempId]"
    :footer="false"
    :get-container="false"
    :wrap-style="{ position: 'absolute' }"
    :bodyStyle="{ padding: 0, background: 'transparent' }"
    @close="handleCancel"
  >
    <div class="info-drawer-content">
      <div class="content">
        <div class="title">{{ $t('map.Learning_maps_overview') }}</div>
        <!-- 学习地图概览 -->
        <div class="vhtml">
          <div class="types">
            <div class="type">
              <span>{{ data.stages.length }}</span>
              <span>{{ $t('Pub_Tab_Checkpoint') }}</span>
              <!-- 关卡 -->
            </div>
            <div class="type">
              <span>{{ data.typesTotal.document }}</span>
              <span>{{ $t('Pub_Tab_Knowledge') }}</span>
              <!-- 知识 -->
            </div>
            <div class="type">
              <span>{{ data.typesTotal.course }}</span>
              <span>{{ $t('CM_Curriculum') }}</span>
              <!-- 课程 -->
            </div>
            <div class="type">
              <span>{{ data.typesTotal.exam }}</span>
              <span>{{ $t('Sys_Exam') }}</span>
              <!-- 考试 -->
            </div>
            <div class="type">
              <span>{{ data.typesTotal.questionnaire }}</span>
              <span>{{ $t('CM_Survey') }}</span>
              <!-- 问卷 -->
            </div>
            <div class="type">
              <span>{{ data.typesTotal.homework }}</span>
              <span>{{ $t('Sys_Homework') }}</span>
              <!-- 实操作业 -->
            </div>
            <div class="type">
              <span>{{ data.typesTotal.teach }}</span>
              <span>{{ $t('mine.help_manage') }}</span>
              <!-- 帮带 -->
            </div>
            <div class="type">
              <span>{{ data.certificateId > 0 ? 1 : 0 }}</span>
              <span>{{ $t('LB_Cert_Certificate') }}</span>
              <!-- 证书 -->
            </div>
          </div>
          <div class="collapse">
            <a-collapse :bordered="false" v-model:activeKey="activeKey">
              <a-collapse-panel
                class="collapse-item"
                v-for="(stage, stageIndex) in data.stages"
                :key="stageIndex + ''"
              >
                <template #header>
                  <div class="collapse-head">
                    <div>
                      <strong>{{ stage.stageName }}</strong>
                      （
                      <template v-if="stage.startUnlock">
                        {{
                          dateFormat(stage.startUnlock) +
                          " - " +
                          dateFormat(stage.endUnlock)
                        }}
                      </template>
                      <template v-else>
                        <template v-if="data.openStageTimeCycle == 1">
                          <!-- {{ stage.effective }} -->
                          <template v-if="stage.dateType == 1">
                            {{ $t('study.learn_in', [stage.effective, $t('Pub_Day')]) }}
                          </template>
                          <template v-else-if="stage.dateType == 2">
                            {{ $t('study.learn_in', [stage.effective, $t('study.month')]) }}
                          </template>
                          <template v-else-if="stage.dateType == 3">
                            {{ $t('study.learn_in', [stage.effective, $t('CM_Year')]) }}
                          </template>
                          <!-- 天/月/年  内学完 -->
                        </template>
                        <template v-else>
                          <!-- {{ stage.effective }} -->
                          <template v-if="data.dateType == 1">
                            {{ $t('study.learn_in', [data.effective, $t('Pub_Day')]) }}
                          </template>
                          <template v-else-if="data.dateType == 2">
                            {{ $t('study.learn_in', [data.effective, $t('study.month')]) }}
                          </template>
                          <template v-else-if="data.dateType == 3">
                            {{ $t('study.learn_in', [data.effective, $t('CM_Year')]) }}
                          </template>
                          <!-- 天/月/年  内学完 -->
                        </template>
                      </template>
                      ）
                    </div>
                    <div>
                      <span v-if="stage.learnFlag">{{ stage.progress }}%</span>
                      <LockOutlined v-else />
                    </div>
                  </div>
                </template>
                <div class="timeline">
                  <a-timeline>
                    <a-timeline-item
                      v-for="(detail, detailIndex) in stage.details"
                      :key="detailIndex"
                    >
                      <div class="timeline-item">
                        <div class="name">
                          [{{ getDetailType(detail.resourceType) }}]
                          {{ detail.detailName }}
                        </div>
                        <div class="status">
                          <span class="gray" v-if="detail.complete === 0"
                            >{{ $t('CM_NotLearning') }}</span
                          >
                          <!-- 未学习 -->
                          <span class="green" v-else-if="detail.complete === 1"
                            >{{ $t('Pub_Completed') }}</span
                          >
                          <!-- 已完成 -->
                          <span class="red" v-else>{{ $t('Pub_Uncompleted') }}</span>
                          <!-- 未完成 -->
                        </div>
                      </div>
                    </a-timeline-item>
                  </a-timeline>
                </div>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </div>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
import { dateFormat } from "@/utils/tools";
import { getDetailType } from "@/utils/business";

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    tempId: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    collapseKey: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      visible: true,
      activeKey: props.collapseKey - 1 + "",
    });

    state.visible = false;

    watch(
      () => props.isVisible,
      (newVal) => {
        state.visible = newVal;
      }
    );
    watch(
      () => props.collapseKey,
      (newVal) => {
        state.activeKey = props.collapseKey - 1 + "";
      }
    );

    const handleCancel = () => {
      emit("cancal");
    };

    return {
      ...toRefs(state),
      dateFormat,
      getDetailType,
      handleCancel,
    };
  },
});
</script>
<style lang="less">
.info-drawer {
  .ant-modal-content {
    background: transparent;
    .ant-modal-close-x {
      font-size: 26px;
      color: #c18805;
    }
  }
  &-content {
    position: relative;
    .content {
      background-color: #fff;
      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      ::-webkit-scrollbar-button:vertical {
        display: none;
      }
      ::-webkit-scrollbar-track,
      ::-webkit-scrollbar-corner {
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: rgba(193, 136, 5, 0.75);
      }
      ::-webkit-scrollbar-thumb:vertical:hover {
        background-color: rgba(193, 136, 5, 0.75);
      }
      ::-webkit-scrollbar-thumb:vertical:active {
        background-color: rgba(193, 136, 5, 0.78);
      }
      .title {
        font-size: 30px;
        line-height: 56px;
        margin: 0;
        color: #c18805;
        padding-left: 20px;
      }
      .vhtml {
        height: 712px;
        padding: 10px 20px 20px;
        overflow-y: scroll;
        .types {
          background-color: #fcf3e6;
          height: 80px;
          .mixinFlex(space-around; center);
          margin-bottom: 20px;
          .type {
            color: #c18805;
            line-height: 30px;
            .mixinFlex(space-around; center; column);
          }
        }
        .collapse {
          .ant-collapse-borderless {
            background-color: #fff;
          }
          &-head {
            .mixinFlex(space-between);
          }
          &-item {
            border: none;
            background-color: #fcf3e6;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
            .ant-collapse-header {
              color: #c18805;
            }
            .timeline {
              margin-top: 10px;
              .ant-timeline-item-head {
                border-color: #c18805;
                background-color: #c18805;
              }
              .ant-timeline-item-content {
                color: #c18805;
              }
              .ant-timeline-item-tail {
                border-left: 2px solid rgba(193, 137, 5, 0.05);
              }
              .ant-timeline-item-last {
                padding: 0;
              }
              .ant-timeline-item-last > .ant-timeline-item-content {
                min-height: unset;
              }
              .timeline-item {
                .mixinFlex(space-between);
                .name {
                  width: calc(100% - 100px);
                }
                .status {
                  .gray {
                    color: gray;
                  }
                  .green {
                    color: green;
                  }
                  .red {
                    color: red;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.info-drawer-temp-1 {
    .ant-modal-content {
      .ant-modal-close-x {
        color: #9d7621;
      }
    }
    .info-drawer-content {
      .content {
        .title {
          color: #a9853c;
        }
        .vhtml {
          .types {
            background-color: #fcf3e6;
            .type {
              color: #a9853c;
            }
          }
          .collapse {
            &-item {
              background-color: #fdf7ea;
              .ant-collapse-header {
                color: #a9853c;
              }
              .timeline {
                .ant-timeline-item-head {
                  border-color: #a9853c;
                  background-color: #a9853c;
                }
                .ant-timeline-item-content {
                  color: #a9853c;
                }
              }
            }
          }
        }
      }
    }
  }
  &.info-drawer-temp-2 {
    .ant-modal-content {
      .ant-modal-close-x {
        color: #5b4e7f;
      }
    }
    .info-drawer-content {
      .content {
        ::-webkit-scrollbar-thumb {
          border-radius: 0;
          background-color: rgba(143, 103, 247, 0.75);
        }
        ::-webkit-scrollbar-thumb:vertical:hover {
          background-color: rgba(143, 103, 247, 0.75);
        }
        ::-webkit-scrollbar-thumb:vertical:active {
          background-color: rgba(143, 103, 247, 0.78);
        }
        .title {
          color: #5b4e7f;
        }
        .vhtml {
          .types {
            background-color: #f8f5fe;
            .type {
              color: #5b4e7f;
            }
          }
          .collapse {
            &-item {
              background-color: #f8f5fe;
              .ant-collapse-header {
                color: #5b4e7f;
              }
              .timeline {
                .ant-timeline-item-head {
                  border-color: #5b4e7f;
                  background-color: #5b4e7f;
                }
                .ant-timeline-item-content {
                  color: #5b4e7f;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
