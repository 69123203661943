<template>
  <div class="rate-mask" v-if="visible">
    <div class="rate-card">
      <!-- 期待您的好评 -->
      <div class="title">{{ $t('course.looking_forward_to_your_positive_comments') }}</div>
      <!-- 您的鼓励能让我们做得更好 -->
      <div class="subtitle">{{ $t('course.your_encouragement_can_make_us_do_better') }}</div>
      <div class="star">
        <a-rate
          v-model:value="star"
          :disabled="disabled"
          style="color: #f5961b; font-size: 32px;"
        />
      </div>
      <div class="btn" v-if="!disabled">
        <a-button type="primary" @click="submit">{{ $t('CM_Submit') }}</a-button>
      </div>
      <div class="close" @click="cancel">
        <CloseCircleOutlined />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { ref, watch } from 'vue';

const props = defineProps({
  score: {
    type: Number,
    default: 0,
  },
  disabled: {
    type: Boolean,
    default: false,
  }
})
const emits = defineEmits(['on-submit']);

const visible = ref(false);
const star = ref(0);
watch(
  () => props.score,
  (val) => {
    star.value = val;
  },
  { immediate: true }
);
const { t: $t } = useI18n();

function show() {
  visible.value = true;
  document.querySelector('body').style.overflow = 'hidden';
}
function cancel() {
  visible.value = false;
  document.querySelector('body').style.overflow = '';
}
function submit() {
  if (star.value) {
    emits('on-submit', star.value);
    cancel();
  }
}
defineExpose({
  show,
  cancel,
});
</script>
<style lang="less" scoped>
.rate-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .4);
  z-index: 99999;
}
.rate-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 298px;
  height: 271px;
  padding: 25px;
  border-radius: 8px;
  text-align: center;
  background: #fff url('~@/assets/image/course/star-bg.png') no-repeat top left;
  background-size: 100%;
  .title {
    margin-top: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  .subtitle {
    margin: 10px 0;
    font-size: 12px;
    color: #999;
  }
  .btn {
    margin-top: 15px;
  }
  .close {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 32px;
    color: #fff;
    cursor: pointer;
    &:hover {
      color: var(--theme);
    }
  }
}
</style>