import request from '@/utils/request';

//课件详情 *
export async function getDetail(params = {}) {
  return request.get(`study/document/${params.id}/detail`, params);
}
//课件附件详情 *
export async function getTrainDetail(params = {}) {
  return request.get(`study/me/${params.id}/file`, params);
}
//课件收藏/取消收藏
export async function favorite(params = {}) {
  return request.post(`study/document/favorite`, params);
}
//课件开始学习
export async function documentStartLearn(params = {}) {
  return request.post(`study/document/startlearn`, params);
}
//知识库开始学习
export async function knowledgeStartLearn(params = {}) {
  return request.post(`study/knowledge/startlearn`, params);
}
//课件学习
export async function documentLearning(params = {}) {
  return request.post(`study/document/learning`, params);
}
//知识库开始学习
export async function knowledgeLearning(params = {}) {
  return request.post(`study/knowledge/learning`, params);
}
//课件点赞/取消点赞
export async function like(params = {}) {
  return request.post(`study/document/like`, params);
}
//删除课件评论/回复
export async function docCommentDel(params = {}) {
  return request.post(`study/document/${params.resourceId}/comment/del`, params);
}
//课件评论点赞/取消点赞
export async function docCommentLike(params = {}) {
  return request.post(`study/document/${params.resourceId}/comment/like`, params);
}
//课件评论/回复
export async function docCommentSubmit(params = {}) {
  return request.post(`study/document/${params.resourceId}/comment/submit`, params);
}
//课件评论列表
export async function docComments(params = {}) {
  return request.post(`study/document/${params.resourceId}/comments`, params);
}


