<template>
  <a-modal
    v-model:visible="visible"
    centered
    :keyboard="false"
    :closable="false"
    :maskClosable="false"
    :footer="null"
    :title="stage.stageName"
    :width="isfull ? '100%' : '1376px'"
    :bodyStyle="{ padding: 0 }"
    :wrapClassName="['study-modal', { 'study-modal-full': isfull }]"
  >
    <button type="button" class="ant-modal-close" @click="beforeCancel">
      <span class="ant-modal-close-x">
        <CloseOutlined />
      </span>
    </button>
    <button type="button" class="ant-modal-full" @click="isfull = !isfull">
      <span class="ant-modal-full-x">
        <FullscreenOutlined v-if="!isfull" />
        <FullscreenExitOutlined v-else />
      </span>
    </button>
    <div class="study-wrap">
      <div class="study-box" v-if="isVisible">
        <doc
          :taskIdP="taskId"
          :sidP="stage.stageId"
          :didP="detail.detailId"
          v-if="detail.resourceType == 1"
        />
        <course
          :taskIdP="taskId"
          :sidP="stage.stageId"
          :didP="detail.detailId"
          v-if="detail.resourceType == 2"
        />
        <exam
          :taskIdP="taskId"
          :didP="detail.detailId"
          source="map"
          v-if="detail.resourceType == 3"
        />
        <questionnaire
          source="map"
          :taskId="taskId"
          :did="detail.detailId"
          :rid="detail.resourceId"
          :progress="detail.progress"
          v-if="detail.resourceType == 4 || detail.resourceType == 7"
          @cancel="handleCancel"
        />
      </div>
    </div>
  </a-modal>
</template>
<script>
import { useI18n } from 'vue-i18n';
import { defineComponent, reactive, toRefs, watch, createVNode } from "vue";
import { useRoute } from "vue-router";
import doc from "@/views/doc/detail";
import course from "@/views/course/detail";
import exam from "@/views/exam/detail";
import questionnaire from "@/views/questionnaire/detail";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";

export default defineComponent({
  components: {
    doc,
    course,
    exam,
    questionnaire,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    stage: {
      type: Object,
      default: () => {},
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const route = useRoute();
    const state = reactive({
      taskId: parseInt(route.query.id || 0),
      visible: false,
      detailName: "",
      isfull: false,
      dataSource: null,
    });

    watch(
      () => props.isVisible,
      (newVal) => {
        state.visible = newVal;
        state.detailName = props.detail.detailName;
        if (!newVal) {
          setTimeout(() => {
            state.isfull = false;
          }, 1000);
        }
      }
    );

    const beforeCancel = () => {
      Modal.confirm({
        title: () => $t('map.close_modal_tip'),
        // 确定关闭当前学习窗口吗？
        icon: () => createVNode(ExclamationCircleOutlined),
        centered: true,
        onOk() {
          handleCancel();
        },
      });
    };

    const handleCancel = () => {
      emit("cancal");
    };

    return {
      ...toRefs(state),
      beforeCancel,
      handleCancel,
    };
  },
});
</script>
<style lang="less">
.study-modal {
  .scrollbar();
  .ant-modal {
    padding-bottom: 0;
  }
  &.study-modal-full {
    .ant-modal {
      max-width: 100%;
      top: 0;
      padding-bottom: 0;
      margin: 0;
    }
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      height: calc(100vh);
    }
    .ant-modal-body {
      flex: 1;
    }
  }
  .study-wrap {
    min-height: 723px;
    height: 100%;
    position: relative;
    .study-box {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;
    }
  }
}
.ant-modal-full {
  position: absolute;
  top: 0;
  right: 50px;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  .ant-modal-full-x {
    display: block;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
  }
}
</style>