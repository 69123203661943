<template>
  <a-modal
    v-model:visible="visible"
    centered
    :keyboard="false"
    :closable="false"
    :maskClosable="false"
    :footer="null"
    :title="null"
    :width="isfull ? '100%' : '1376px'"
    :bodyStyle="{ padding: 0 }"
    :wrapClassName="['study-modal', { 'study-modal-full': isfull }]"
  >
    <button type="button" class="ant-modal-close" @click="beforeCancel">
      <span class="ant-modal-close-x">
        <CloseOutlined />
      </span>
    </button>
    <button type="button" class="ant-modal-full" @click="isfull = !isfull">
      <span class="ant-modal-full-x">
        <FullscreenOutlined v-if="!isfull" />
        <FullscreenExitOutlined v-else />
      </span>
    </button>
    <div class="study-wrap">
      <div class="study-box" v-if="isVisible">
        <a-tabs>
          <!-- 帮带计划 -->
          <a-tab-pane key="1" :tab="$t('map.help_and_plan')" v-if="detail.teach.usePlan == 1">
            <div class="plan-box">
              <h3>{{ $t('teaching.mentor_program') }}：</h3>
              <!-- 导师计划： -->
              <div class="plan" v-if="memberStudy.tutorPlan">
                <div v-html="memberStudy.tutorPlan"></div>
                <div class="btns">
                  <a-button
                    class="btn"
                    type="primary"
                    @click="planSubmit"
                    :disabled="memberStudy.teachPlan == 1"
                    >{{ $t('map.i_already_know__confirm_done') }}</a-button
                  >
                  <!-- 我已知晓，确认完成 -->
                </div>
              </div>
              <a-empty
                :description="$t('map.tutor_no_plan')"
                v-else
                style="padding: 80px 0 0"
              />
              <!-- 导师暂未制定计划 -->
            </div>
          </a-tab-pane>
          <!-- 评价导师 -->
          <a-tab-pane
            key="2"
            :tab="$t('map.evaluation_mentor')"
            v-if="detail.teach.useEvaluate == 1 && detail.teach.stuId"
          >
            <div>
              <questionnaire
                source="map-teach"
                :taskId="taskId"
                :did="detail.detailId"
                :rid="detail.teach.stuId"
                :targetUserId="detail.teach.targetUId"
                :evalType="2"
                :progress="detail.teach.teachStu == 1 ? 100 : 0"
                @cancel="handleCancel"
              />
            </div>
          </a-tab-pane>
          <!-- 帮带总结 -->
          <a-tab-pane key="3" :tab="$t('map.help_to_summarize')" v-if="detail.teach.useSum == 1">
            <div class="sum-box">
              <p>{{ $t('teaching.require') }}：{{ teach.sumRequirement }}</p>
              <!-- 总结要求： -->
              <p v-if="teach.scoreType == 1">
                <!-- 总结总分： -->
                {{ $t('teaching.total_score') }}：{{
                  teach.totalScore
                }}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <!-- 合格总分： -->
                {{ $t('teaching.passing_score') }}：{{
                  parseInt(teach.totalScore * (teach.passLine / 100))
                }}
              </p>
              <div class="input">
                <span>{{ $t('map.submit_summary') }}：</span>
                <!-- 提交总结： -->
                <a-textarea
                  :rows="10"
                  v-model:value="memberStudy.userSum"
                  :placeholder="$t('map.submit_summary_p')"
                  :maxlength="2000"
                  showCount
                  :disabled="sumDisabled"
                />
                <!-- 提交长内容时，建议先用其他软件编辑好，再粘贴到这里 -->
              </div>
              <div class="files">
                <div class="up-ctrl">
                  <a-button
                    :disabled="sumDisabled"
                    @click="$refs.secretFileRef.show()"
                    ><UploadOutlined /> {{ $t('LB_UploadingAttachments') }}</a-button
                  >
                  <!-- 上传附件 -->
                  <p class="explain">
                    {{ $t('upload.accept', [5, '200M']) }}
                    <!-- 支持图片、文档、音频、视频，最多可上传5个附件，单个文件不超过200M -->
                  </p>
                </div>
                <ul class="upload-list">
                  <li class="file-item" v-for="(file, i) in memberStudy.files" :key="i">
                    <PaperClipOutlined />
                    <span class="file-item-name">
                      <template v-if="file.secretData">
                        {{ htsySecret[file.secretData] }}
                      </template>
                      {{ file.name }}
                    </span>
                    <DeleteOutlined class="del" @click="removeFile(i)" />
                  </li>
                </ul>
              </div>
              <div v-if="memberStudy.reviewStatus == 1">
                <div class="input2" v-if="teach.scoreType == 1">
                  <span class="label">{{ $t('LB_Favorite_Score') }}：</span>
                  <!-- 评分： -->
                  <a-input-number
                    v-model:value="memberStudy.reviewScore"
                    :min="0"
                    :max="teach.totalScore"
                    :precision="0"
                    disabled
                  />
                  <span>
                    <!-- 满分 -->
                    &nbsp;&nbsp;&nbsp;&nbsp; {{ $t('LB_Favorite_Score') }}
                    {{ $t('teaching.fill_score', [teach.totalScore]) }}
                    &nbsp;&nbsp;
                    <!-- 分 -->
                    {{ 
                    $t('teaching.num_score_pass', [parseInt(teach.totalScore * (teach.passLine / 100))])
                    }}
                    <!-- 分合格 -->
                  </span>
                </div>
                <div class="input2" v-if="teach.scoreType == 2">
                  <span class="label">{{ $t('LB_Favorite_Score') }}：</span>
                  <!-- 评价： -->
                  <a-radio-group
                    v-model:value="memberStudy.reviewResult"
                    disabled
                  >
                    <a-radio :value="1">{{ $t('teaching.excellent') }}</a-radio>
                    <!-- 优秀 -->
                    <a-radio :value="2">{{ $t('teaching.qualified') }}</a-radio>
                    <!-- 合格 -->
                    <a-radio :value="3">{{ $t('teaching.unqualified') }}</a-radio>
                    <!-- 不合格 -->
                  </a-radio-group>
                </div>
                <div class="input2" v-if="memberStudy.reviewComment">
                  <span class="label">{{ $t('CM_LB_Reviews') }}：</span>
                  <!-- 评语： -->
                  <a-textarea
                    v-model:value="memberStudy.reviewComment"
                    :rows="10"
                    :maxlength="2000"
                    showCount
                    :placeholder="$t('teaching.please_enter_a_comment')"
                    disabled
                  />
                  <!-- 请输入评语 -->
                </div>
              </div>
              <div class="btns">
                <a-button
                  class="btn"
                  type="primary"
                  @click="sumSubmit"
                  :disabled="sumDisabled"
                  >
                  {{ memberStudy.status == 1 ? $t('map.continue_to_submit') : $t('CM_Submit') }}
                  <!-- 继续提交 ： 提交 -->
                </a-button>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <secretFile
      ref="secretFileRef"
      :config="{
        action: 'study/upload/file',
        accept: $t('upload.accept', [5, '200M']),
        maxSize: 200,
        limit: 5,
      }"
      @ok="upEnd"
    />
    <!-- 支持图片、文档、音频、视频，最多可上传5个附件，单个文件不超过200M -->
  </a-modal>
</template>
<script>
import { useI18n } from 'vue-i18n';
import {
  defineComponent,
  reactive,
  toRefs,
  watch,
  createVNode,
  getCurrentInstance,
  ref,
} from "vue";
import { useRoute } from "vue-router";
import { uploadUrl, getHeader } from "@/utils/tools";
import { teachDetail, summarizeSubmit, planConfirm } from "@/api/map";
import questionnaire from "@/views/questionnaire/detail2";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import secretFile from "@/components/secretFile.vue";
import { htsySecret } from "@/utils/business";

export default defineComponent({
  components: {
    questionnaire,
    secretFile,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    stage: {
      type: Object,
      default: () => {},
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const state = reactive({
      taskId: parseInt(route.query.id || 0),
      visible: false,
      isfull: false,
      dataSource: null,
      memberStudy: {},
      teach: {},
      sumDisabled: false,
    });

    watch(
      () => props.isVisible,
      (newVal) => {
        state.visible = newVal;
        if (!newVal) {
          setTimeout(() => {
            state.isfull = false;
          }, 1000);
        } else {
          teachDetail(state.taskId, props.detail.detailId).then((res) => {
            state.memberStudy = res.data.memberStudy;
            state.teach = res.data.teach;
            state.sumDisabled = false;
            let doneType = state.teach.doneType; //	1 提交总结完成 2 批阅合格完成
            let scoreType = state.teach.scoreType; //	1 直接评分 2 是否合格 3 不评分
            let reviewStatus = state.memberStudy.reviewStatus; // 0 未批阅 1 已批阅
            let reviewResult = state.memberStudy.reviewResult; // 1 优秀 2 合格 3 不合格
            debugger
            if (doneType == 1) {
              if (reviewStatus == 1) {
                state.sumDisabled = true;
              }
            } else if (state.teach.doneType == 2) {
              if (reviewStatus == 1) {
                if (reviewResult == 1 || reviewResult == 2) {
                  state.sumDisabled = true;
                }
              }
            }
            let files = state.memberStudy.attachments || [];
            state.memberStudy.files = files.map((file) => {
              return {
                name:
                  file.fileName +
                  file.filePath.substr(file.filePath.lastIndexOf(".")),
                filePath: file.filePath,
                status: "done",
                secretData: file.secretData,
                response: {
                  data: {
                    name: file.fileName,
                    file: file.filePath,
                    fileSize: file.fileSize,
                    fileType: file.fileType,
                  },
                },
              };
            });
          });
        }
      }
    );

    const beforeCancel = () => {
      Modal.confirm({
        title: () => $t('map.close_modal_tip'),
        // 确定关闭当前学习窗口吗？
        icon: () => createVNode(ExclamationCircleOutlined),
        centered: true,
        onOk() {
          handleCancel();
        },
      });
    };

    const handleCancel = () => {
      emit("cancal");
    };

    const upLoadChange = (info) => {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
      } else if (info.file.status === "error") {
        proxy.$message.error(`${info.file.name} ${$t('upload.failed')}`);
        // 上传失败
      }
    };
    const secretFileRef = ref(null);

    const upEnd = ({ secret, file }) => {
      console.log(file);
      state.memberStudy.files.push(...file);
    };

    const removeFile = (i) => {
      state.memberStudy.files.splice(i, 1);
    };

    const sumSubmit = () => {
      let files = state.memberStudy.files || [];
      if (state.memberStudy.userSum.trim() == "") {
        proxy.$message.warn($t('map.please_fill_out_summary_content'));
        // 请填写总结内容
        return false;
      }
      if (files.length > 5) {
        proxy.$message.warn($t('upload.max_count_tip', [5]));
        // 最多可上传5个附件
        return false;
      }
      files = files.map((item) => {
        if (item.status == "done") {
          return {
            fileName: item.response.data.name,
            filePath: item.response.data.file,
            fileSize: item.response.data.fileSize || item.size,
            fileType: item.response.data.fileType || 0,
            secretData: item.secretData,
          };
        }
      });
      summarizeSubmit({
        attachments: files,
        content: state.memberStudy.userSum,
        detailId: props.detail.detailId,
        taskId: state.taskId,
      }).then((res) => {
        if (res.ret == 0) {
          proxy.$message.success($t('LB_Teacher_SubSuccess'));
          // 提交成功
          handleCancel();
        }
      });
    };

    const planSubmit = () => {
      planConfirm({
        detailId: props.detail.detailId,
        taskId: state.taskId,
      }).then((res) => {
        proxy.$message.success($t('LB_Teacher_SubSuccess'));
        // 提交成功
        handleCancel();
      });
    };

    return {
      uploadUrl,
      getHeader,
      ...toRefs(state),
      beforeCancel,
      handleCancel,
      upLoadChange,
      planSubmit,
      sumSubmit,
      htsySecret,
      secretFileRef,
      upEnd,
      removeFile,
    };
  },
});
</script>
<style lang="less">
.study-modal {
  .scrollbar();
  .ant-modal {
    padding-bottom: 0;
  }
  &.study-modal-full {
    .ant-modal {
      max-width: 100%;
      top: 0;
      padding-bottom: 0;
      margin: 0;
    }
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      height: calc(100vh);
    }
    .ant-modal-body {
      flex: 1;
    }
  }
  .study-wrap {
    min-height: 723px;
    height: 100%;
    position: relative;
    .study-box {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;
    }
  }
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 20px;
  }
  .plan-box {
    padding: 4px 180px 16px;
    h3 {
      font-size: 18px;
      font-weight: normal;
      margin-bottom: 16px;
    }
    textarea {
      resize: none;
    }
  }
  .sum-box {
    padding: 4px 180px 16px;
    .input {
      .mixinFlex();
      span {
        width: 78px;
        position: relative;
        &::before {
          content: "*";
          color: red;
          position: absolute;
          top: 1px;
          left: -10px;
        }
      }
      .ant-input-textarea {
        width: calc(100% - 78px);
      }
    }
    .input2 {
      .mixinFlex();
      margin-top: 10px;
      line-height: 33px;
      .label {
        width: 78px;
        position: relative;
      }
      .ant-input-textarea {
        width: calc(100% - 78px);
      }
    }
    .files {
      padding-left: 78px;
      .up-ctrl {
        .mixinFlex(flex-start; center);
        .explain {
          padding-left: 20px;
          margin: 0;
          color: #666;
        }
      }
    }
  }
  .btns {
    margin-top: 50px;
    text-align: center;
    .btn {
      background-color: @color-theme;
      border-color: @color-theme;
      color: #fff;
      width: 172px;
      height: 48px;
      border-radius: 24px;
      &:disabled {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
      }
    }
  }
}
.ant-modal-full {
  position: absolute;
  top: 0;
  right: 50px;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  .ant-modal-full-x {
    display: block;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
  }
}
.upload-list {
  .file-item {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #f5f5f5;
    &-name {
      flex: 1;
      margin: 0 10px;
    }
  }
}
</style>
