<template>
  <!-- 人脸识别 -->
  <a-modal v-model:visible="visible" :width="600" :footer="null" centered :closable="false" :maskClosable="false" :keyboard="false" :title="$t('XB_FacialIdentification')">
    <div class="face-wrap">
      <div class="face" :class="{ ok: loading ? true : hasFace }">
        <video id="video" width="290" height="290" preload autoplay loop muted></video>
        <canvas id="canvas" width="290" height="290"></canvas>
      </div>
    </div>
    <p class="tips">{{ $t('course.face_tip', 0) }}<span>{{ $t('course.face_tip', 1) }}</span>{{ $t('course.face_tip', 2) }}</p>
    <!-- 请将您的<span>脸部</span>放入识别框内识别 -->
    <div class="btns">
      <a-button type="primary" :loading="loading" :disabled="loading ? false : !hasFace" @click="submitPhoto" class="btn">{{ $t('CM_Confirm') }}</a-button>
    <!-- 确定 -->
    </div>
  </a-modal>
</template>

<script>
require("@/assets/js/tracking-min.js");
require("@/assets/js/face-min.js");
require("@/assets/js/dat.gui.min.js");
require("@/assets/js/stats.min.js");
import { useI18n } from 'vue-i18n';
import { ref, onUnmounted, onMounted, nextTick } from "vue";
import { uploadAvatar } from '@/api/user'
import ls from "@/utils/local-storage";
export default {
  name: "Face",
  props: {
    name: {
      require: true,
      type: String,
    },
  },
  emits: ["confirm"],
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const hasFace = ref(false);
    const visible = ref(false);
    const loading = ref(false);
    let userInfo = ls.get('userInfo');

    let trackerTask = null;
    let mediaStreamTrack = null;

    const openCamera = () => {
      mediaStreamTrack = document.getElementById("video");
      let canvas = document.getElementById("canvas");
      let context = canvas.getContext("2d");

      let tracker = new window.tracking.ObjectTracker("face");
      tracker.setInitialScale(4);
      tracker.setStepSize(2);
      tracker.setEdgesDensity(0.1);

      trackerTask = window.tracking.track("#video", tracker, { camera: true });

      tracker.on("track", function (event) {
        if (event.data.length == 1) {
          hasFace.value = true;
        } else {
          hasFace.value = false;
        }
        context.clearRect(0, 0, canvas.width, canvas.height);
        event.data.forEach(function (rect) {
          context.font = "12px Helvetica";
          context.fillStyle = "#2ad82a";
          context.fillText($t('course.face_click_tip'), 74, 40);
          // 已识别到人脸，请点击确定
          context.strokeStyle = "#2ad82a";
          context.strokeRect(rect.x, rect.y, rect.width, rect.height);
        });
      });
    };

    const submitPhoto = () => {
			loading.value = true;
      let canvas = document.getElementById("canvas");
      let context = canvas.getContext("2d");
      let video = document.getElementById("video");
      context.drawImage(video, 0, 0, 290, 290);
      canvas.toBlob((blob) => {
        let fileList = new File([blob], new Date()+'.png',{type: 'image/png'});
        let formData = new FormData();
        formData.append("file", fileList);
        uploadAvatar(formData).then(res => {
          if(res.ret == 0){
            emit("confirm", res.data);
            ls.set('faceAcquisition_' + props.name + '_' + userInfo.userId, true);
            visible.value = false;
            if (trackerTask) trackerTask.stop();
            if (mediaStreamTrack) mediaStreamTrack.srcObject.getTracks()[0].stop();
          }
        });
      });
    };

    onMounted(() => {
      // 验证是否已采集过人脸
      let unFaceAcquisition = ls.get("faceAcquisition_" + props.name + "_" + userInfo.userId);
      if (!Boolean(unFaceAcquisition)) {
        visible.value = true;
        nextTick(() => {
          openCamera();
        });
      } else {
        emit("confirm");
      }
    });

    onUnmounted(() => {
      if (trackerTask) trackerTask.stop();
      if (mediaStreamTrack) mediaStreamTrack.srcObject.getTracks()[0].stop();
    });

    return {
      visible,
      loading,
      hasFace,
      submitPhoto,
    };
  },
};
</script>

<style lang="less" scoped>
.face-wrap {
  .mixinFlex(center; center);
  .face {
    width: 290px;
    height: 290px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    font-size: 0;
    padding: 0;
    margin: 0;
    #video,
    #canvas {
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 0;
      padding: 0;
      margin: 0;
    }
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border: 6px solid red;
      z-index: 10;
      border-radius: 50%;
    }
    &.ok {
      &::before {
        border: 6px solid #2ad82a;
      }
    }
  }
}
.tips {
  font-size: 16px;
  margin: 15px 0 0;
  text-align: center;
  span {
    color: @color-theme;
    padding: 0 6px;
  }
}
.btns {
  padding: 30px;
  .mixinFlex(center; center);
  .btn {
    width: 290px;
    height: 50px;
    font-size: 16px;
  }
}
</style>






