<template>
  <a-modal
    v-model:visible="visible"
    :maskClosable="false"
    :keyboard="false"
    width="500px"
    :title="$t('exam.examPassword')"
  >
    <a-form ref="formRef" :model="formState" :rules="rules">
      <a-form-item :label="$t('exam.examPassword')" name="password">
        <a-input
          v-model:value="formState.password"
          :maxlength="50"
          :placeholder="$t('LB_QA_PleaseEnter') + $t('exam.examPassword')"
        />
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="ft">
        <a-button type="primary" @click="handleOk">{{
          $t("LB_Exam_StartExam")
        }}</a-button>
      </div>
    </template>
  </a-modal>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive, getCurrentInstance } from "vue";

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const visible = ref(false);
const formRef = ref(null);
const formState = reactive({
  val: "",
  password: "",
});
const rules = {
  password: [
    {
      required: true,
      message: $t("LB_QA_PleaseEnter") + $t("exam.examPassword"),
      trigger: "blur",
    },
  ],
};

function show(val) {
  visible.value = true;
  formState.val = val;
}

const emit = defineEmits(["emit"]);
function handleOk() {
  formRef.value
    .validate()
    .then(() => {
      if (formState.val === formState.password) {
        emit("complete", true);
      } else {
        // 考试密码错误
        proxy.$message.error($t("exam.examPasswordError"));
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
}
defineExpose({
  show,
});
</script>
<style lang="less" scoped>
.signature-container {
  height: 200px;
  margin-top: 10px;
  background-color: #f5f5f5;
}
</style>
