import request from '@/utils/request';

//新闻列表 *
export async function newslist(params = {}) {
  return request.post(`study/supervision/news/list`, params);
}
//新闻目录 *
export async function newsfolder(params = {}) {
  return request.get(`study/supervision/news/folder/list`, params);
}
//新闻详情 *
export async function newsdetail(params = {}) {
  return request.post(`study/supervision/news/detail`, params);
}
//新闻点赞 *
export async function newslike(params = {}) {
  return request.post(`study/supervision/news/like`, params);
}
//新闻阅读 *
export async function newsread(params = {}) {
  return request.post(`study/supervision/news/read`, params);
}
//删除课件评论/回复
export async function newsCommentDel(params = {}) {
  return request.post(`study/supervision/news/${params.resourceId}/comment/del`, params);
}
//课件评论点赞/取消点赞
export async function newsCommentLike(params = {}) {
  return request.post(`study/supervision/news/${params.resourceId}/comment/like`, params);
}
//课件评论/回复
export async function newsCommentSubmit(params = {}) {
  return request.post(`study/supervision/news/${params.resourceId}/comment/submit`, params);
}
//课件评论列表
export async function newsComments(params = {}) {
  return request.post(`study/supervision/news/${params.resourceId}/comments`, params);
}


