<template>
  <a-modal
    v-model:visible="visible"
    destroyOnClose
    centered
    :keyboard="false"
    width="800px"
    :class="['info-dialog', 'info-dialog-temp-' + tempId]"
    :footer="false"
    :bodyStyle="{ padding: 0, background: 'transparent' }"
    @cancel="handleCancel"
  >
    <div class="info-dialog-content">
      <div class="head"></div>
      <div class="content">
        <div class="title">{{ $t('map.learning_map_desc') }}</div>
        <!-- 学习地图说明 -->
        <div class="vhtml" v-html="data.intro"></div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRefs, watch } from 'vue';

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    tempId: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      visible: false,
    });

    watch(
      () => props.isVisible,
      newVal => {
        state.visible = newVal;
      },
    );

    const handleCancel = () => {
      emit('cancal');
    };

    return {
      ...toRefs(state),
      handleCancel,
    };
  },
});
</script>
<style lang="less">
.info-dialog {
  .ant-modal-content {
    background: transparent;
    .ant-modal-close-x {
      font-size: 26px;
      color: #c18805;
    }
  }
  &-content {
    position: relative;
    .head {
      background-color: #febd27;
      height: 32px;
      position: absolute;
      top: -16px;
      left: -16px;
      right: -16px;
      border-radius: 16px;
      z-index: -1;
    }
    .content {
      background-color: #fff;
      height: 560px;
      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      ::-webkit-scrollbar-button:vertical {
        display: none;
      }
      ::-webkit-scrollbar-track,
      ::-webkit-scrollbar-corner {
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: rgba(193, 136, 5, 0.75);
      }
      ::-webkit-scrollbar-thumb:vertical:hover {
        background-color: rgba(193, 136, 5, 0.75);
      }
      ::-webkit-scrollbar-thumb:vertical:active {
        background-color: rgba(193, 136, 5, 0.78);
      }
      .title {
        text-align: center;
        font-size: 30px;
        line-height: 56px;
        margin: 0;
        color: #c18805;
      }
      .vhtml {
        height: 504px;
        padding: 20px;
        overflow-y: scroll;
      }
    }
  }
  &.info-dialog-temp-1 {
    .ant-modal-content {
      .ant-modal-close-x {
        color: #9d7621;
      }
    }
    .info-dialog-content {
      .head {
        background-color: #a9853c;
      }
      .content {
        .title {
          color: #a9853c;
        }
      }
    }
  }
  &.info-dialog-temp-2 {
    .ant-modal-content {
      .ant-modal-close-x {
        color: #5b4e7f;
      }
    }
    .info-dialog-content {
      .head {
        background-color: #8f67f7;
      }
      .content {
        ::-webkit-scrollbar-thumb {
          border-radius: 0;
          background-color: rgba(143, 103, 247, 0.75);
        }
        ::-webkit-scrollbar-thumb:vertical:hover {
          background-color: rgba(143, 103, 247, 0.75);
        }
        ::-webkit-scrollbar-thumb:vertical:active {
          background-color: rgba(143, 103, 247, 0.78);
        }
        .title {
          color: #5b4e7f;
        }
      }
    }
  }
}
</style>