<template>
  <!-- 考试签名 -->
  <a-modal
    v-model:visible="visible"
    :maskClosable="false"
    :keyboard="false"
    width="600px"
    :title="$t('exam.exam_signature')"
    @cancel="cancel"
  >
    <div class="desc">{{ $t('exam.exam_signature_tip') }}</div>
    <!-- 本场考试开启了"签名确认"，请在下面签名框内使用鼠标签名（长按鼠标） -->
    <div class="signature-container">
      <vue-esign
        ref="esignRef"
        :width="config.width"
        :height="config.height"
        :lineWidth="config.lineWidth"
        :lineColor="config.strokeStyle"
      ></vue-esign>
    </div>
    <template #footer>
      <div class="ft">
        <a-button :loading="loading" @click="clear">{{ $t('exam.clear') }}</a-button>
        <!-- 清除 -->
        <a-button :loading="loading" type="primary" @click="handleOk">{{ $t('CM_Confirm') }}</a-button>
        <!-- 确定 -->
      </div>
    </template>
  </a-modal>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { ref, reactive, getCurrentInstance } from 'vue';
import vueEsign from 'vue-esign'
import { uploadFile } from '@/api/user';
import { signExam } from "@/api/exam";

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const visible = ref(false);
const esignRef = ref(null);
const config = reactive({
  width: 550,
  height: 200,
  fillStyle: '#f5f5f5',
  lineWidth: 8,
  strokeStyle: '#000000',
  lineCap: 'round',
  lineJoin: 'round',
});
const state = reactive({
  submitId: 0,
  courseApi: '',
})

function dataURLtoBlob(dataurl) { 
    var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

function clear() {
  esignRef.value.reset();
}

function cancel() {
  clear();
  emit('complete');
}

function show(id, course) {
  visible.value = true;
  state.submitId = id;
  state.courseApi = course ? 'course/' : '';
};

let loading = ref(false);
const emit = defineEmits(['complete']);
function handleOk() {
  esignRef.value.generate().then(res => {
    const blob = dataURLtoBlob(res);
    const date = Date.now().toString();
    let formData = new FormData();
    formData.append("file", blob, `${date}签名.png`);
    loading.value = true;
    uploadFile(formData).then(res => {
      if (res.ret === 0) {
        const param = {
          file: res.data.file,
          submitId: state.submitId,
        }
        signExam(param, state.courseApi).then(res => {
          if (res.ret === 0) {
            proxy.$message.success($t('exam.signed_successfully'));
            // 签名提交成功
            emit('complete');
            visible.value = false;
          } else {
            proxy.$message.error($t('exam.signature_failed'));
            // 签名提交失败
          }
        });
      }
    }).finally(() => {
      loading.value = false;
    })
  }).catch(err => {
    return proxy.$message.error($t('exam.please_sign'));
    // 请签名！
  })
};
defineExpose({
  show,
});
</script>
<style lang="less" scoped>
.signature-container {
  height: 200px;
  margin-top: 10px;
  background-color: #f5f5f5;
}
</style>